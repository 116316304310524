export const BASE = '/';

export const HOURS_PIVOT = '/hours';
export const SELECT_HOURS = '/hours/$';

export const FORM_PIVOT = '/form';
export const FORM_FIELD = '/form/$';

export const WHATS_APP_PIVOT = '/whatsApp';

export const IMAGE = '/image';
